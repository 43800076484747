import axios from "axios";
import Vue from 'vue';


// const initCurWorkspace = {
//     ComputerName: '',
//     DirectoryId: '',
//     IpAddress: '',
//     State: '',
//     UserName: '',
//     WorkspaceId: '',
//     WorkspaceProperties: {}
// };

const state = {
    workspaces: {},
    curWorkspace: {},
};

const getters = {
    getWorkspaces: (s) => s.workspaces,
    getCurWorkspace: (s) => s.curWorkspace,
};

const actions = {
    async loadAllWorkspaces({ commit }) {
        let url = '/workspaces/get_all_workspaces/';
        let resp = await axios.get(url);

        if (resp.data.success) commit('SET_WORKSPACE', resp.data.workspaces);
    },
    async getWorkspaceByUni({ commit }, payload) {
        let url = `/workspaces/get_workspace/${payload}/`;
        let resp = await axios.get(url);
        
        if (resp.data.success && resp.data.workspace.length > 0) {
            commit('SET_CUR_WORKSPACE', resp.data.workspace[0])
            commit('ADD_WORKSPACE', resp.data.workspace[0])
        } else {
            commit('setSnack', {message: "Could not find user's workspace", color: 'error'}, { root: true });
        }
    },
    async getSessionsByUni({commit}, payload){
        let url = `/workspaces/get_sessions/${payload}/`;
        let resp = await axios.get(url);
        
        if (resp.data.success && Object.keys(resp.data.sessions).length > 0) {
            commit('SET_CUR_WORKSPACE', resp.data.sessions)
            commit('ADD_WORKSPACE', resp.data.sessions)
        } else {
            commit('setSnack', {message: "Could not find user's workspace", color: 'error'}, { root: true });
        }
    },
    async rebootWorkspaceById({commit}, payload) {
        let url = `/workspaces/reboot_workspace/${payload}/`;
        let resp = await axios.put(url);

        if (resp.data.success) {
            return {success: true};
        } else {
            commit('setSnack', {message: resp.data.msg, color: 'error'}, { root: true });
        }
    },
    async restoreWorkspaceById({commit}, payload) {
        let url = `/workspaces/restore_workspace/${payload}/`;
        let resp = await axios.put(url);
        
        if (resp.data.success) {
            return {success: true};
        } else {
            commit('setSnack', {message: resp.data.msg, color: 'error'}, { root: true });
        }
    },
    async rebootMachineById(_, payload) {
        let id = payload.id;
        let instance = payload.source;
        let url = `/workspaces/reboot_machine/${instance}/${id}/`;
        let resp = await axios.put(url);
        if (resp.data.success) return {success: true};
    },
    removeWorkspaceByUni({ commit }, payload) {
        commit('REMOVE_WORKSPACE_BY_UNI', payload);
    },
    removeWorkspaceById({ commit }, payload) {
        // commit('REMOVE_WORKSPACE_BY_UNI', payload);
        commit('REMOVE_WORKSPACE_BY_ID', payload);

    },
    reset({ commit }) {
        commit('RESET')
    }
};

const mutations = {
    SET_WORKSPACES(state, payload){
        state.workspaces = payload
    },
    SET_CUR_WORKSPACE(state, payload){
        // state.curWorkspace = Object.assign(
        //     JSON.parse(JSON.stringify(initCurWorkspace)),
        //     payload
        // );
        state.curWorkspace = payload;
    },
    ADD_WORKSPACE(state, payload) {
        for (const key in payload) {
            let value = payload[key]
            // if (!Object.prototype.hasOwnProperty.call(state.workspaces, value.UserName)) {
            //     state.workspaces[value.UserName] = []
            // }
            // state.workspaces[value.UserName].push(value)
            state.workspaces[value.id] = value;
        }
    },
    REMOVE_WORKSPACE_BY_UNI(state, payload) {
        // payload should just be uni
        Vue.delete(state.workspaces, payload);
    },
    REMOVE_WORKSPACE_BY_ID(state, payload) {
        // let workspaces = state.workspaces[payload.UserName];
        Vue.delete(state.workspaces, payload.id);
        // state.workspaces[payload.UserName] = state.workspaces[payload.UserName].filter(item => item.id !== payload.id);
        // if (state.workspaces[payload.UserName].length == 0) {
        //     Vue.delete(state.workspaces, payload.UserName);
        // }
    },
    RESET(state) {
        state.workspaces = {};
        state.curWorkspace = {};
    }
};

const workspaceAdminModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default workspaceAdminModule;
